'use client';

import React, { PropsWithChildren } from 'react';

export const ApplicationContext = React.createContext<string | null>(null);

export function ApplicationContextProvider({ applicationId, children = null }: PropsWithChildren<{ applicationId: string }>) {
  return (
    <ApplicationContext.Provider value={applicationId}>
      {children}
    </ApplicationContext.Provider>
  );
}
