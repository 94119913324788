import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/globals.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/app/[lang]/i18n/useTranslation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/auth.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/ExitIntentSurvey.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/FeatureFlagProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/posthogClient.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/PostHogPageView.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vendor-dashboard/src/lib/queryProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.1_fefdc11bff642853cc82e817f9deba36/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query-devtools@5.52.0_497f4222d3ecbecab63cc0f40e4639f0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/jotai@2.9.3_32ef43c880bb70d0eecbdf2a30a1cf8d/node_modules/jotai/esm/react.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_8a2a1eee516835a8040c05f34baf174e/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_8a2a1eee516835a8040c05f34baf174e/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/components/index.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_8a2a1eee516835a8040c05f34baf174e/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}")