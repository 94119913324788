'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogSession } from './featureFlags';

if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  throw Error('Missing PostHog key');
}

if (typeof window !== 'undefined') {
  const getCookie = (name: string) => {
    const cookie: Record<string, string> = {};
    document.cookie.split(';').forEach((el) => {
      const split = el.split('=');
      const key = split[0]?.trim();
      if (key) {
        cookie[key] = split.slice(1).join('=');
      }
    });
    return cookie[name];
  };

  const bootstrap = getCookie('ph_bootstrap');
  const parsedBootstrap = bootstrap ? JSON.parse(decodeURIComponent(bootstrap)) as PostHogSession : null;

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    bootstrap: parsedBootstrap ? {
      distinctID: parsedBootstrap.distinctId,
      featureFlags: parsedBootstrap.flags,
    } : undefined,
  });
}

export default function PostHogProviderClient({ children }: React.PropsWithChildren) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
