import { atom } from 'jotai';

export { atom };

export const atomWithLocalStorage = <V>(key: string, initialValue: V) => {
  const getInitialValue = ():V => {
    let item:string|null = null;
    if (typeof window !== 'undefined') {
      item = localStorage.getItem(key);
    }
    if (item === undefined || item === 'undefined' || item === null) {
      return initialValue;
    }
    return (JSON.parse(item) as V);
  };
  const baseAtom = atom<V>(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update:(a:V) => V) => {
      const nextValue:V = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      if (typeof window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(nextValue));
      }
    },
  );
  return (derivedAtom as (typeof baseAtom));
};
