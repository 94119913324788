import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB2tH1JJ12xtqaoR9qnpt_vZ3wMl511YL4',
  authDomain: 'otmow-br.firebaseapp.com',
  projectId: 'otmow-br',
  storageBucket: 'unverified-contracts',
  messagingSenderId: '1033864426383',
  appId: '1:1033864426383:web:6993dea797c15379f411fa',
  measurementId: 'G-X8Y2VFGXPH',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

const auth = getAuth(firebase);

export { auth, firebase };
