'use client';

import otmowService from '@repo/common/services/otmowService';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { auth } from '@/firebaseConfig';

const isAuth = atom(null as boolean | null);

export function useIsAuth() {
  const [hasToken] = useAtom(isAuth);
  return hasToken;
}

export function useLogout(to?: string) {
  const router = useRouter();
  return (href?: string) => {
    auth.signOut().then(() => {
      router.push(href ?? to ?? '/intake');
    });
  };
}

export default function Auth() {
  const setIsAuth = useSetAtom(isAuth);
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleAuthStateChanged = (authUser: FirebaseUser | null) => {
      setIsAuth(!!authUser);
      if (authUser) {
        otmowService.handleAuthChange(authUser);
      }
      queryClient.clear();
    };
    const unsubscribe = onAuthStateChanged(auth, (authUser) => { handleAuthStateChanged(authUser); });

    return () => unsubscribe();
  }, []);

  return undefined;
}
