'use client';

import React from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import handleError from '@/lib/handleError';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      handleError({ error });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _v, _c, mutation) => {
      if (mutation?.meta?.overwriteBaseError) {
        return;
      }
      handleError({ error });
    },
  }),
});

export default function QueryProvider({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
}
