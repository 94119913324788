'use client';

import { Button, Input, Modal } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Survey } from 'posthog-js';
import otmowService from '@repo/common/services/otmowService';
import { usePathname } from 'next/navigation';
import { useTranslation } from '@/app/[lang]/i18n/useTranslation';
import { useCompanies, useContextApplication } from '@/app/[lang]/loan/queries';
import { useIsAuth } from './auth';

const pathlist = [
  '/review',
  '/funds-sent',
  '/waiting-for-payment',
  '/review-documents',
];

function useSendExitSurvey() {
  const { data: applicationData } = useContextApplication();
  const { data: companies } = useCompanies();
  const pathname = usePathname();

  const shouldSendExitSurvey = pathlist.every((path) => !pathname.includes(path));
  const phoneNumber = companies?.at(0)?.mainRepresentative?.whatsappNumber;
  const applicationId = applicationData?.application?.id;

  return () => {
    if (!shouldSendExitSurvey) {
      throw Error('Exit survey not needed');
    }
    if (sessionStorage.getItem('exitSurvey')) {
      throw Error('Exit survey already sent');
    }
    if (!phoneNumber || !applicationId) {
      throw Error('No phone number found');
    }
    const result = otmowService.receivables.representative.postWhatsAppExitSurveyTest(
      encodeURIComponent(phoneNumber),
      applicationId,
    );
    if (result) {
      sessionStorage.setItem('exitSurvey', 'true');
    }
  };
}

function useMobileExitIntent(onExitIntent: () => void) {
  const triggerExitIntent = () => {
    onExitIntent();
  };

  useEffect(() => {
    let startY = 0;
    let lastY = 0;
    const scrollThreshold = 100; // Adjust this value to control sensitivity of scroll detection

    const onTouchStart = () => {
      startY = window.scrollY || document.documentElement.scrollTop;
    };

    const onTouchEnd = () => {
      lastY = window.scrollY || document.documentElement.scrollTop;
      if (lastY < startY && Math.abs(lastY - startY) < scrollThreshold) {
        triggerExitIntent();
      }
    };

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchend', onTouchEnd);
    };
  }, [onExitIntent]);
}

function Selectable({ label, selected, onClick }: { label: string; selected: boolean; onClick: () => void }) {
  function check() {
    if (selected) {
      return (
        <div className="aspect-square w-4 border border-blue-400 border-solid rounded-full bg-white flex items-center justify-center">
          <div className="aspect-square w-2.5 border border-blue-400 border-solid rounded-full bg-blue-400" />
        </div>
      );
    }
    return (
      <div className="aspect-square w-4 border border-black border-solid rounded-full bg-white" />
    );
  }

  return (
    <div
      className="flex items-center border border-black/20 border-solid rounded-lg px-4 py-2 text-base cursor-pointer hover:bg-black/5 hover:border-black/50 aria-checked:hover:border-blue-400 aria-checked:border-blue-400/50 aria-checked:font-medium transition-colors gap-2"
      role="checkbox"
      aria-checked={selected}
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
    >
      {check()}
      {label}
    </div>
  );
}

export default function ExitIntentSurvey() {
  const isAuth = useIsAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [survey, setSurvey] = useState<Survey | null>(null);
  const choices = survey && survey.questions[0] && 'choices' in survey.questions[0] ? survey.questions[0].choices : [];

  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const [reason, setReason] = useState<string>('');

  const sendExitSurvey = useSendExitSurvey();

  function handleSubmit() {
    if (selectedOption.length > 0 && survey) {
      localStorage.setItem(`seenSurvey_${survey.id}`, 'true');
      const answers = reason !== '' ? [...selectedOption, reason] : selectedOption;
      posthog.capture('survey sent', {
        $survey_id: survey.id,
        $survey_name: survey.name,
        $survey_response: answers,
      });
      setIsModalOpen(false);
    }
  }

  function handleClose() {
    if (survey) {
      localStorage.setItem(`seenSurvey_${survey.id}`, 'true');
      posthog.capture('survey dismissed', {
        $survey_id: survey.id,
        $survey_name: survey.name,
      });
    }
    setIsModalOpen(false);
  }

  const handleExitIntent = useCallback(() => {
    if (isAuth) {
      return;
    }
    posthog.getActiveMatchingSurveys((surveys) => {
      const foundSurvey = surveys.find((s) => s.id === '018fdf18-47f4-0000-791b-a7b13fdad426');
      if (foundSurvey) {
        const seenSurvey = localStorage.getItem(`seenSurvey_${foundSurvey.id}`);
        if (seenSurvey) {
          return;
        }
        posthog.capture('survey shown', {
          $survey_id: foundSurvey.id,
        });
        setSurvey(foundSurvey);
        setIsModalOpen(true);
      }
    });
  }, [posthog, setIsModalOpen, setSurvey, isAuth]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const clearMouseLeaveDetection = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const detectMouseLeave = useCallback((e: MouseEvent) => {
    const LEAVE_DELAY = 200;
    timeoutRef.current = setTimeout(() => {
      const x = e.clientX;
      const y = e.clientY;
      if (x < 0 || x > window.innerWidth || y < 0 || y > window.innerHeight) {
        handleExitIntent();
      }
      clearMouseLeaveDetection();
    }, LEAVE_DELAY);
  }, [handleExitIntent, clearMouseLeaveDetection]);

  useEffect(() => {
    document.addEventListener('mouseleave', detectMouseLeave);
    document.addEventListener('mouseenter', clearMouseLeaveDetection);
    return () => {
      document.removeEventListener('mouseleave', detectMouseLeave);
      document.removeEventListener('mouseenter', clearMouseLeaveDetection);
    };
  }, [detectMouseLeave]);

  useMobileExitIntent(() => {
    handleExitIntent();
  });

  useEffect(() => {
    function onBeforeUnload(e: BeforeUnloadEvent) {
      if (sessionStorage.getItem('exitSurvey')) {
        return;
      }
      sendExitSurvey();
      e.preventDefault();
    }

    if (isAuth) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [isAuth, sendExitSurvey]);

  return (
    <Modal
      title={
        <div className="text-2xl">{t('intake.exit_intent.title')}</div>
      }
      open={isModalOpen}
      onCancel={() => handleClose()}
      footer={null}
      centered
    >
      <p className="text-base">{t('intake.exit_intent.description')}</p>
      <section className="space-y-2">
        {choices.map((choice) => (
          <Selectable
            key={choice}
            label={choice}
            selected={selectedOption.includes(choice)}
            onClick={() => {
              setSelectedOption((prev) => (
                prev.includes(choice)
                  ? prev.filter((k) => k !== choice)
                  : [...prev, choice]
              ));
            }}
          />
        ))}
        {selectedOption.includes('Outro') && (
          <div>
            <Input.TextArea className="text-base" placeholder={t('intake.exit_intent.reason')} value={reason} onChange={(e) => setReason(e.target.value)} />
          </div>
        )}
      </section>
      <div>
        <Button block type="primary" className="mt-8" size="large" onClick={() => handleSubmit()}>
          { survey?.questions.at(0)?.buttonText || '...' }
        </Button>
      </div>
    </Modal>
  );
}
